<template>
  <div>
    <!--
    <div v-if="!facilityLevel">
      <span v-if="editMode" class="ml-6"
      >The name and description for this lease.</span 
      >
      <span v-else class="ml-6">
        <span class="font-weight-medium">Step 1:</span> Provide a name and
        description for this lease.</span
      >
      <v-divider class="mt-3"></v-divider>
    </div>
    -->
    <hb-form
      label="Name"
      :required="!readMode"
      :editable="(editMode && isPermissionToEdit) || (!editMode && !readMode)"
      :start-open="!editMode && !readMode"
      :hide-actions="!editMode && !readMode"
      :active.sync="active"
      :empty="!template.name && !readMode"
      @cancel="handleEmittedCancel"
      @save="handleEmittedSave"
    >
      <template v-slot:display>
        {{ template.name }}
      </template>
      <template v-slot:edit>
        <hb-text-field
          v-model="template.name"
          v-validate="'required|max:45'"
          id="template_name"
          name="template_name"
          data-vv-scope="form_1"
          data-vv-as="Name"
          :error="errors.has('form_1.template_name')"
          placeholder="Enter Name"
          autofocus
        ></hb-text-field>
      </template>
    </hb-form>

    <hb-form
      label="Description"
      :editable="(editMode && isPermissionToEdit) || (!editMode && !readMode)"
      :start-open="!editMode && !readMode"
      :hide-actions="!editMode && !readMode"
      :active.sync="active"
      :empty="!template.description"
      full
      @cancel="handleEmittedCancel"
      @save="handleEmittedSave"
    >
      <template v-slot:display>
        {{ template.description }}
      </template>
      <template v-slot:edit>
        <hb-textarea
          v-model="template.description"
          v-validate="'max:1000'"
          name="description"
          id="description"
          data-vv-scope="form_1"
          data-vv-as="Description"
          :error="errors.has('form_1.description')"
          placeholder="Enter Description"
          :autofocus="editMode"
        ></hb-textarea>
      </template>
    </hb-form>

    <span v-if="showSpaceType !== false">

      <hb-form
        label="Space Type"
        :required="!readMode"
        :editable="(editMode && isPermissionToEdit) || (!editMode && !readMode)"
        :start-open="!editMode && !readMode"
        :hide-actions="!editMode && !readMode"
        :active.sync="active"
        :empty="!template.unit_type_id && !readMode"
        @cancel="handleEmittedCancel"
        @save="handleEmittedSave"
        last
        :content-position="contentPosition"
      >
        <template v-slot:display>
          {{ getUnitTypeTitle(template) | capitalize }}

          <hb-checkbox
            v-model="template.is_default"
            label="Set as Default for All Properties"
            readonly
            class="pt-2"
          >

          </hb-checkbox>
        </template>
        <template v-slot:edit>
          <hb-select
            v-model="template.unit_type_id"
            :items="getUnitTypes()"
            v-validate="'required'"
            id="unit_type"
            name="unit_type"
            data-vv-scope="form_1"
            data-vv-as="Space Type"
            :error="errors.has('form_1.unit_type')"
            placeholder="Select Space Type"
            :clearable="false"
            class="general-cofigurations-hb-select-fix-wrapper"
            item-text="display_name"
            item-value="unit_type_id"
          >
          </hb-select>

          <hb-checkbox
            v-model="template.is_default"
            id="checkbox-is-default"
            name="checkbox-is-default"
            data-name="checkbox-is-default"
            label="Set as Default for All Properties"
          >
          </hb-checkbox>
        </template>
      </hb-form>

    </span>

  </div>
</template>

<script type="text/babel">
import { mapGetters } from "vuex";
import { EventBus } from '../../../EventBus.js';

export default {
  name: "GeneralConfigurations",
  props: ["template", "activelyEditing", "editMode", "readMode", "facilityLevel", "showSpaceType", "contentPosition"],
  data() {
    return {
      active: '',
      isCheckBoxChecked: false,
      isFormStatusSave: false,

    };
  },
  created(){
    EventBus.$on('lease-configuration-edit-mode-clear-active', this.clearActive);
  },
  destroyed() {
    EventBus.$off('lease-configuration-edit-mode-clear-active', this.clearActive);
  },
  computed: {
    ...mapGetters({
      hasPermission: 'authenticationStore/rolePermission'
    }),
    isPermissionToEdit() {
      return this.facilityLevel ? false : this.hasPermission('view_edit_templates');
    },
  },
  methods: {
    async validateForm() {
      let status = await this.$validator.validateAll("form_1");
      this.isFormStatusSave = (status?true:false)
      return status;
    },
    handleEmittedCancel(){
      this.$emit('cancelEdit');
    },
    handleEmittedSave(){
      this.$emit('saveData');
    },
    clearActive(){
      this.active = '';
    }
  },
  watch: {
    "template.unit_type_id"(value) {
      if(this.template && this.template.unit_type_id && value !== 'parking') {
        this.template.checklist["vehicle"] = [];
      }
    },
    // Modal checks checkbox's default value: if 1, checkbox disabled; else, enabled.
    "template.is_default"(value) {
      if(value === 1){
        this.isCheckBoxChecked = true;
      }
    },
    // Checkbox is enabled for editing if its not disabled already and gets disabled after it's saved. 
    "activelyEditing"(value){
      if(this.template.is_default && this.isFormStatusSave && value === ""){
        this.isCheckBoxChecked = true;
      }
    }
  }
};
</script>

<style scoped>
.field-label {
  background-color: #f9fafb;
  color: #101318;
}

.general-cofigurations-hb-select-fix-wrapper >>> .v-select__selection.v-select__selection--comma {
  text-transform: capitalize !important;
}

.general-configurations-select-text-night-fix {
  color: #101318 !important;
}
</style>
